<template>
  <div>
    <i
      class="fa-solid fa-play"
      @click.stop="playSound"
      v-if="!audioStarted"
    ></i>

    <div v-else>
      <i
        class="fa-solid fa-play text-green-400"
        @click.stop="play"
        v-if="audioPlayed"
      ></i>
      <i class="fa-solid fa-pause text-red-400" @click.stop="pause" v-else></i>
    </div>

    <audio controls v-if="true" class="audio-player-element">
      <source src="" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
import { ref } from "vue";
import useSoundFiles from "@/modules/xpbx/composables/useSoundFiles";

export default {
  name: "AudioPlayer",
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { findSoundFIle } = useSoundFiles();
    const audioStarted = ref(false);
    const audioPlayed = ref(false);

    const playSound = async () => {
      const response = await findSoundFIle(props.id);
      const audioPlayerElement = document.querySelector(
        ".audio-player-element"
      );

      if (response) {
        const blob = new Blob([response], { type: "audio/mpeg" });

        const blobUrl = URL.createObjectURL(blob);

        audioPlayerElement.src = blobUrl;
        setTimeout(() => {
          audioStarted.value = true;
          audioPlayerElement.addEventListener("ended", () => {
            audioStarted.value = false;
            audioPlayed.value = false;
          });
          audioPlayerElement.play();
        }, 300);
      }
    };

    const play = () => {
      const audioPlayerElement = document.querySelector(
        ".audio-player-element"
      );
      audioPlayerElement.play();
      audioPlayed.value = !audioPlayed.value;
    };

    const pause = () => {
      const audioPlayerElement = document.querySelector(
        ".audio-player-element"
      );
      audioPlayerElement.pause();
      audioPlayed.value = !audioPlayed.value;
    };

    return {
      play,
      pause,
      playSound,
      audioPlayed,
      audioStarted,
    };
  },
};
</script>

<style lang="scss" scoped>
.audio-player-element {
  display: none;
}
</style>
<template>
  <div>
    <div class="relative mt-4 flex gap-4">
      <div class="w-2/4">
        <!--Name -->
        <div class="field flex items-center gap-3">
          <label class="w-28" for="name">{{
            $t("xpbx.placeholders.name")
          }}</label>
          <InputText
            id="name"
            v-model.trim="form.name"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !form.name }"
          />
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.name')" />

        <!--Strategy -->
        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="strategy">{{
            $t("xpbx.placeholders.strategy")
          }}</label>
          <div class="w-30">
            <form-select
              v-model="form.strategy"
              :options="strategyOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.strategy')" />

        <!--Queue number -->
        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="queue_number">{{
            $t("xpbx.placeholders.queue_number")
          }}</label>
          <InputText
            id="queue_number"
            v-model.trim="form.queue_number"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !form.queue_number }"
          />
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.number')" />

        <!--Wrapuptime -->
        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="wrapuptime">{{
            $t("xpbx.placeholders.wrapuptime")
          }}</label>
          <div class="w-20">
            <form-select
              v-model="form.wrapuptime"
              :options="wrapuptimeOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.wrapuptime')" />

        <!--Agent period -->
        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="find_agent_period">{{
            $t("xpbx.placeholders.find_agent_period")
          }}</label>
          <div class="w-20">
            <form-select
              v-model="form.find_agent_period"
              :options="agentPeriodOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.agent_period')" />
        <!--Timeout -->
        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="timeout">{{
            $t("xpbx.placeholders.timeout")
          }}</label>
          <div class="w-20">
            <form-select
              v-model="form.timeout"
              :options="timeoutOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.timeout')" />

        <!--Weight -->
        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="company">{{
            $t("xpbx.placeholders.weight")
          }}</label>
          <div class="w-20">
            <form-select
              v-model="form.weight"
              :options="weightOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.weight')" />
      </div>

      <!-- Right container -->
      <div class="w-2/5">
        <!-- Unblock -->
        <div class="field mt-4 flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="is_active">{{
            $t("xpbx.labels.unblock")
          }}</label>
          <InputSwitch v-model="form.unblock" />
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.unblock')" />

        <!-- Connect again now -->
        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="is_active">{{
            $t("xpbx.labels.connect_agent_now")
          }}</label>
          <InputSwitch v-model="form.connect_agent_now" />
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.connect_again')" />

        <div class="field flex justify-start items-center gap-3">
          <label class="w-28 text-left" for="is_active">{{
            $t("xpbx.labels.is_active")
          }}</label>

          <div class="field flex justify-start items-center gap-3">
            <InputSwitch v-model="form.is_active" />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.is_active')" />

        <div class="flex justify-start mt-5">
          <Button
            label="Save"
            icon="pi pi-plus"
            severity="success"
            class="mr-2 add-record-button"
            @click="edit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Button from "primevue/button";
import { useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "QueueGeneral",

  components: {
    Button,
    InputText,
    InputSwitch,
    QueueHint,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();
    const submitted = ref(false);
    const form = ref({
      ...props.data,
      unblock: props.data.unblock === 1 ? true : false,
      is_active: props.data.is_active === 1 ? true : false,
      connect_agent_now: props.data.connect_agent_now === 1 ? true : false,
    });
    const { errors, clearErrors } = validator();
    const { updateQueueGeneral } = useQueues();
    const {
      activeOptions,
      weightOptions,
      timeoutOptions,
      wrapuptimeOptions,
      agentPeriodOptions,
      strategyOptions,
    } = useQueueOptions();

    const edit = async () => {
      submitted.value = true;

      // Call the API to update the queue
      const formData = {
        ...form.value,
        unblock: form.value.unblock ? 1 : 0,
        is_active: form.value.is_active ? 1 : 0,
        connect_agent_now: form.value.connect_agent_now ? 1 : 0,
      };

      await updateQueueGeneral(formData, route.params.id);
    };

    return {
      form,
      errors,
      submitted,
      weightOptions,
      activeOptions,
      timeoutOptions,
      strategyOptions,
      wrapuptimeOptions,
      agentPeriodOptions,

      edit,
      clearErrors,
      updateQueueGeneral,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>

import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useQueueOptions() {
  const activeOptions = [
    { name: "Active", value: 1 },
    { name: "Inactive", value: 0 },
  ];

  const timeoutOptions = [
    { name: "0", value: 30 },
    { name: "60", value: 60 },
    { name: "90", value: 90 },
    { name: "120", value: 120 },
    { name: "150", value: 150 },
    { name: "180", value: 180 },
    { name: "210", value: 210 },
    { name: "240", value: 240 },
    { name: "270", value: 270 },
    { name: "300", value: 300 },
    { name: "330", value: 330 },
    { name: "360", value: 360 },
    { name: "390", value: 390 },
    { name: "420", value: 420 },
    { name: "450", value: 450 },
    { name: "480", value: 480 },
    { name: "510", value: 510 },
    { name: "540", value: 540 },
    { name: "570", value: 570 },
    { name: "600", value: 600 },
  ];

  const wrapuptimeOptions = [
    { name: "0", value: 0 },
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "15", value: 15 },
    { name: "20", value: 20 },
    { name: "25", value: 25 },
    { name: "30", value: 30 },
    { name: "35", value: 35 },
    { name: "40", value: 40 },
    { name: "45", value: 45 },
    { name: "50", value: 50 },
    { name: "55", value: 55 },
    { name: "60", value: 60 },
    { name: "65", value: 65 },
    { name: "70", value: 70 },
    { name: "75", value: 75 },
    { name: "80", value: 80 },
    { name: "85", value: 85 },
    { name: "90", value: 90 },
    { name: "95", value: 95 },
    { name: "100", value: 100 },
    { name: "105", value: 105 },
    { name: "110", value: 110 },
    { name: "115", value: 115 },
    { name: "120", value: 120 },
  ];

  const weightOptions = [
    { name: "-1", value: -1 },
    { name: "0", value: 0 },
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
    { name: "4", value: 4 },
    { name: "5", value: 5 },
    { name: "6", value: 6 },
    { name: "7", value: 7 },
    { name: "8", value: 8 },
    { name: "9", value: 9 },
    { name: "10", value: 10 },
  ];
  //   for (let i = 0; i <= 60; i++) {
  //     agentPeriodOptions.push({ name: i.toString(), value: i });
  //   }

  const agentPeriodOptions = [
    { name: "0", value: 0 },
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "15", value: 15 },
    { name: "20", value: 20 },
    { name: "25", value: 25 },
    { name: "30", value: 30 },
    { name: "35", value: 35 },
    { name: "40", value: 40 },
    { name: "45", value: 45 },
    { name: "50", value: 50 },
    { name: "55", value: 55 },
    { name: "60", value: 60 },
  ];

  const strategyOptions = [
    { name: "Ringall", value: "ringall" },
    { name: "Random", value: "random" },
    { name: "Ordered", value: "ordered" },
  ];

  const fileTypeOptions = [
    { name: "File for IVR", value: "ivr" },
    { name: "Music on HOLD", value: "moh" },
  ];

  const actionOptions = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
  ];

  return {
    actionOptions,
    activeOptions,
    weightOptions,
    timeoutOptions,
    strategyOptions,
    fileTypeOptions,
    wrapuptimeOptions,
    agentPeriodOptions,
  };
}

import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors, isValidUrl } = validator();

export function validateCreatedQueueAction(data) {
  clearErrors();

  data = unref(data);

  checkRequired("name", data);
  // checkRequired("action", data);
  // checkRequired("value", data);
  // checkRequired("timerange", data);
  // checkRequired("secondTimerange", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueRule(data) {
  clearErrors();

  data = unref(data);

  checkRequired("name", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueMember(data) {
  clearErrors();

  data = unref(data);

  checkRequired("auto_answer", data);
  checkRequired("workplace", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueBlacklist(data) {
  clearErrors();

  data = unref(data);

  checkRequired("calling_number", data);
  checkRequired("valid_to", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueVip(data) {
  clearErrors();

  data = unref(data);

  checkRequired("calling_number", data);
  checkRequired("weight", data);

  return hasErrors.value ? false : true;
}

import { ref, inject } from "vue";
import api from "@/services/userApi";
// import api from "@/services/api";

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export default function useTimezones() {
  const t = inject("t");
  const timezone = ref([]);
  const timezones = ref({
    description: "",
    priority: 1,
    is_active: 1,
  });
  const openingHours = ref({});
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  const checkDayRange = (data) => {
    const rangeArray = [];

    for (const range of data) {
      const start = range[0];
      const end = range[1] !== "00:00:00" ? range[1] : "24:00:00";

      rangeArray.push([start, end]);
    }

    return rangeArray;
  };

  const transformOpeningHours = (data) => {
    const daysArray = {};
    for (const day of days) {
      if (data?.[day]) {
        const dayRange = checkDayRange(data[day]);
        daysArray[day] = dayRange;
      } else {
        daysArray[day] = [];
      }
    }
    return daysArray;
  };

  //   FIND TIMEZONES
  const findTimezones = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/timezone");

      if (response?.data) timezones.value = response.data;
    } catch (error) {}
  };

  //   FIND TIMEZONE
  const findTimezone = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/timezone/${id}`);

      if (response?.data) {
        const openingHoursArray = transformOpeningHours(response.data);
        openingHours.value = openingHoursArray;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   CREATE TIMEZONE
  const createTimezone = async (data) => {
    try {
      const response = await api.post(`/admin/xpbx/settings/timezone`, data);

      if (response?.data?.id) {
        const oldTimezones = [...timezones.value];
        const newTemplate = [...oldTimezones, response.data];
        timezones.value = newTemplate;
        n("notify.xpbx.create_timezone", "success");
      } else {
        n("notify.xpbx.error_create_timezone");
      }
    } catch (error) {
      n("notify.xpbx.error_create_timezone");
    }
  };

  //   UPDATE TIMEZONE
  const updateTimezone = async (data, id) => {
    try {
      const response = await api.put(
        `/admin/xpbx/settings/timezone/${id}`,
        data
      );

      if (response?.data?.id) {
        const oldTimezones = [...timezones.value];
        const newTimezones = oldTimezones.map((i) =>
          i.id === id ? response.data : i
        );

        timezones.value = newTimezones;
        n("notify.xpbx.edit_timezone", "success");
      } else {
        n("notify.xpbx.error_edit_timezone", "success");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_timezone", "success");
    }
  };

  // UPDATE TIMEZONE DATA
  const updateTimezoneData = async (data, id) => {
    try {
      const response = await api.patch(
        `/admin/xpbx/settings/timezone/${id}`,
        data
      );

      if (response?.data) {
        n("notify.xpbx.edit_timezone", "success");
      } else {
        n("notify.xpbx.error_edit_timezone", "success");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_timezone", "success");
    }
  };

  //   DELETE TIMEZONE
  const deleteTimezone = async (id) => {
    try {
      const response = await api.delete(`/admin/xpbx/settings/timezone/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_timezone", "success");
        timezones.value = timezones.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_timezone");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_timezone");
      return error;
    }
  };

  return {
    timezone,
    timezones,
    openingHours,
    findTimezone,
    findTimezones,
    createTimezone,
    updateTimezone,
    updateTimezoneData,
    deleteTimezone,
  };
}

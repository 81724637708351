<template>
  <div>
    <div class="card relative table-wrapper">
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        v-if="data && data?.length"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        :rowClass="rowClass"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
        v-model:selection="selectedRecords"
      >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                :label="$t('xpbx.button.new')"
                icon="pi pi-plus"
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
              />
              <Button
                :label="$t('xpbx.button.delete')"
                icon="pi pi-trash"
                severity="danger"
                class="delete-button"
                @click="confirmDeleteSelected"
                :disabled="!selectedRecord?.id"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 50%"
          field="calling_number"
          header="Calling number"
        ></Column>

        <Column
          sortable
          style="width: 50%"
          field="weight"
          header="Weight"
        ></Column>
      </DataTable>

      <Loader v-else />
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.calling_number">
          {{
            $t("notify.xpbx.message.confirm_delete", {
              delete: `number ${selectedRecord?.calling_number}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          text
          @click="deleteSelectedRecords"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.pages.heading.edit_vip_number')
          : $t('xpbx.pages.heading.create_vip_number')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <div class="field mb-1">
        <label for="calling_number">{{ $t("xpbx.placeholders.number") }}</label>
        <InputText
          id="calling_number"
          v-model.trim="record.calling_number"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !record.calling_number }"
        />
        <small class="p-error" v-if="!!errors?.calling_number?.[0]">{{
          errors?.calling_number?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-vnumber.calling_number')" />

      <!-- Action -->
      <div class="field mb-1">
        <label for="weight">{{ $t("xpbx.placeholders.weight") }}</label>
        <InputText
          id="weight"
          v-model.trim="record.weight"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !record.weight }"
        />
        <small class="p-error" v-if="!!errors?.weight?.[0]">{{
          errors?.weight?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-vnumber.weight')" />

      <!-- Is active -->
      <div class="field mb-1 mt-4 flex items-center">
        <label for="is_active" class="mr-3">{{
          $t("xpbx.placeholders.active")
        }}</label>
        <InputSwitch v-model="record.is_active" />
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-vnumber.is_active')" />
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          text
          @click="createDialog = false"
        />
        <Button label="Save" icon="pi pi-check" text @click="create" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import { validateCreatedQueueVip } from "@/composables/auth/queueValidatuions";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "VipNumbers",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText,
    InputSwitch,
    Loader,
    QueueHint,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup() {
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);

    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();

    // Data table functions
    const openNew = () => {
      record.value = {
        calling_number: "",
        weight: "",
        is_active: false,
      };
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      record.value = {
        calling_number: "",
        weight: "",
        is_active: false,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecords.value.id) {
        await deleteQueueOption(
          selectedRecord.value,
          id,
          selectedRecords.value.id,
          "vip-numbers"
        );
      }
      deleteDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        const form = {
          ...event.data,
          is_active: event.data.is_active === 1 ? true : false,
        };
        record.value = form;
        isEdit.value = true;
        createDialog.value = true;
      }
    };

    const updateRecord = async (data) => {
      const id = route.params.id;
      const formData = {
        ...data,
        is_active: data.is_active ? 1 : 0,
      };

      if (formData.id) {
        await updateQueueOption(formData, id, formData.id, "vip-numbers");
      }
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreatedQueueVip(data);
      if (isValid) {
        const id = route.params.id;
        const formData = {
          ...data,
          is_active: data.is_active ? 1 : 0,
        };

        await createQueueOption(formData, id, "vip-numbers");
        createDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": isEdit.value && record?.value?.id === data?.id,
        },
      ];
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      checkTableClick();
    });

    return {
      record,
      isEdit,
      errors,
      submitted,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      rowClass,
      // Data table functions
      reset,
      create,
      openNew,
      closeDialog,
      onRowSelect,
      onRowUnselect,
      createRecord,
      updateRecord,
      createQueueOption,
      updateQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>
<template>
  <div class="bg-white w-full p-3">
    <section class="h-full w-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.queue')"
        :showButton="false"
      />
      <QueueTabs :data="queueData" />
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onMounted, computed } from "vue";
import useQueues from "@/modules/xpbx/composables/useQueues";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import QueueTabs from "./components/queue-tabs/index.vue";

export default {
  name: "QueueDetail",

  components: {
    PageHeader,
    QueueTabs,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const { queue, findQueue } = useQueues();

    const queueData = computed(() => store.state.xadmin.queue);

    onMounted(async () => {
      await findQueue(route.params.id);
      store.commit("xadmin/SET_XADMIN_QUEUE", queue.value);
    });

    return {
      queue,
      queueData,
      findQueue,
    };
  },
};
</script>

<style>
</style>
import { ref, inject } from "vue";
import api from "@/services/userApi";

export default function useSoundFiles() {
  const t = inject("t");
  const soundFile = ref({
    description: "",
    filename: "",
    filetype: "ivr",
    audio: null,
  });
  const soundFiles = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND SOUND FILES
  const findSoundFIles = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/sound-file");

      if (response?.data) soundFiles.value = response.data;
    } catch (error) {}
  };

  //   FIND SOUND FILE
  const findSoundFIle = async (id) => {
    try {
      const response = await api.getAudio(
        `/admin/xpbx/settings/sound-file/${id}`
      );

      return response?.data ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  //   CREATE SOUND FILE
  const createSoundFIle = async (data) => {
    try {
      const response = await api.post(`/admin/xpbx/settings/sound-file`, data);

      if (response?.data?.length) {
        const oldArray = [...soundFiles.value];
        const newSoundFiles = [...oldArray, response.data?.[0]];
        soundFiles.value = newSoundFiles;
        n("notify.xpbx.create_sound_file", "success");
      } else {
        n("notify.xpbx.error_create_sound_file");
      }
    } catch (error) {
      n("notify.xpbx.error_create_sound_file");
    }
  };

  //   UPDATE SOUND FILE
  const updateSoundFIle = async (data, id) => {
    try {
      const response = await api.post(
        `/admin/xpbx/settings/sound-file/${id}`,
        data
      );

      if (response?.data?.length) {
        const oldRecords = [...soundFiles.value];

        const exFile = response?.data.find((i) => i.id === id);
        const newArray = oldRecords.map((i) => (i.id === id ? exFile : i));

        soundFiles.value = newArray;
        n("notify.xpbx.edit_sound_file", "success");
      } else {
        n("notify.xpbx.error_edit_sound_file", "success");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_sound_file", "success");
    }
  };

  //   DELETE SOUND FILE
  const deleteSoundFIle = async (id) => {
    try {
      const response = await api.delete(
        `/admin/xpbx/settings/sound-file/${id}`
      );

      if (response.status === 200) {
        n("notify.xpbx.delete_sound_file", "success");
        soundFiles.value = soundFiles.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_sound_file");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_sound_file");
      return error;
    }
  };

  return {
    soundFile,
    soundFiles,
    findSoundFIle,
    findSoundFIles,
    createSoundFIle,
    updateSoundFIle,
    deleteSoundFIle,
  };
}

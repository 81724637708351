import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useQueues() {
  const t = inject("t");
  const queue = ref({});
  const queues = ref([]);
  const store = useStore();
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND QUEUES
  const findQueues = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/queue");
      if (response?.data) queues.value = response.data;
    } catch (error) {}
  };

  //   FIND QUEUE
  const findQueue = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/queue/${id}`);

      if (response?.data) queue.value = response.data;
    } catch (error) {
      console.log(error);
    }
  };

  //   UPDATE QUEUE GENERAL
  const updateQueueGeneral = async (data, id) => {
    try {
      const response = await api.put(`/admin/xpbx/settings/queue/${id}`, data);

      if (response?.data) {
        const key = "queue-general";
        const form = { key, value: response.data };
        store.commit("xadmin/UPDATE_XADMIN_QUEUE_OPTION", form);

        n("notify.xpbx.edit_queue_option", "success");
      } else {
        n("notify.xpbx.error_edit_queue_otpion");
      }
    } catch (error) {
      console.log(error);
      n("notify.xpbx.error_edit_queue_otpion");
    }
  };

  //   UPDATE QUEUE OPTION
  const updateQueueOption = async (data, id, recordId, url) => {
    try {
      const response = await api.put(
        `/admin/xpbx/settings/queue/${id}/${url}/${recordId}`,
        data
      );

      if (response?.data) {
        const key = getQueueField(url);
        const form = { key, value: response.data };
        store.commit("xadmin/UPDATE_XADMIN_QUEUE_OPTION", form);

        n("notify.xpbx.edit_queue_option", "success");
      } else {
        n("notify.xpbx.error_edit_queue_otpion");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_queue_otpion");
    }
  };

  const getQueueField = (url) => {
    switch (url) {
      case "rules":
        return "queue-rules";
      case "actions":
        return "queue-actions";
      case "black-numbers":
        return "queue-bnumbers";
      case "vip-numbers":
        return "queue-vnumbers";
      case "members":
        return "queue-members";
      default:
        return "";
    }
  };

  // CREATE QUEUE OPTION
  const createQueueOption = async (data, id, url) => {
    try {
      const response = await api.post(
        `/admin/xpbx/settings/queue/${id}/${url}`,
        data
      );

      if (response?.data) {
        const key = getQueueField(url);
        const form = { key, value: response.data };
        store.commit("xadmin/ADD_XADMIN_QUEUE_OPTION", form);

        n("notify.xpbx.create_queue_option", "success");
      } else {
        n("notify.xpbx.error_create_queue_otpion");
      }
    } catch (error) {
      n("notify.xpbx.error_create_queue_otpion");
    }
  };

  // CREATE QUEUE
  const createQueue = async (data) => {
    try {
      const response = await api.post(`/admin/xpbx/settings/queue`, data);

      if (response?.data) {
        const key = getQueueField(url);
        const form = { key, value: response.data };
        store.commit("xadmin/ADD_XADMIN_QUEUE", form);

        n("notify.xpbx.create_queue_option", "success");
      } else {
        n("notify.xpbx.error_create_queue_otpion");
      }
    } catch (error) {
      n("notify.xpbx.error_create_queue_otpion");
    }
  };

  // DELETE QUEUE
  const deleteQueue = async (id) => {
    try {
      const response = await api.delete(`/admin/xpbx/settings/queue/${id}`);
      if (response?.status === 200) {
        queues.value = queues.value.filter((item) => item.id !== id);

        n("notify.xpbx.delete_queue", "success");
      } else {
        n("notify.xpbx.error_delete_queue");
      }
    } catch (error) {
      n("notify.xpbx.error_delete_queue");
    }
  };

  // DELETE QUEUE OPTION
  const deleteQueueOption = async (data, id, recordId, url) => {
    try {
      const response = await api.delete(
        `/admin/xpbx/settings/queue/${id}/${url}/${recordId}`
      );

      const key = getQueueField(url);
      const form = { key, value: recordId };
      store.commit("xadmin/DELETE_XADMIN_QUEUE_OPTION", form);

      n("notify.xpbx.delete_queue_option", "success");
    } catch (error) {
      console.log(error);
      n("notify.xpbx.error_delete_queue_otpion");
    }
  };

  return {
    queue,
    queues,
    findQueue,
    findQueues,
    createQueue,
    deleteQueue,
    updateQueueOption,
    updateQueueGeneral,
    createQueueOption,
    deleteQueueOption,
  };
}

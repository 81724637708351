<template>
  <div>
    <div class="card relative table-wrapper">
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        selectionMode="single"
        :rowClass="rowClass"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
        v-model:selection="selectedRecords"
      >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                :label="$t('xpbx.button.new')"
                icon="pi pi-plus"
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
              />
              <Button
                :label="$t('xpbx.button.delete')"
                icon="pi pi-trash"
                severity="danger"
                class="delete-button"
                @click="confirmDeleteSelected"
                :disabled="!selectedRecord?.id"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column sortable style="width: 25%" field="name" header="Name"></Column>
        <Column
          sortable
          style="width: 25%"
          field="increase_weight"
          header="Increase weight"
        >
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="period"
          header="Period"
        ></Column>
        <Column
          sortable
          style="width: 25%"
          field="timezone"
          header="Timezone"
        ></Column>
      </DataTable>

      <!-- <Loader v-else /> -->
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.name">
          {{
            $t("notify.xpbx.message.confirm_delete", {
              delete: `domain ${selectedRecord?.name}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          text
          @click="deleteSelectedRecords"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.pages.heading.edit_queue_rule')
          : $t('xpbx.pages.heading.create_queue_rule')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <div class="field mb-1">
        <label for="description mb-2">{{ $t("xpbx.placeholders.name") }}</label>
        <InputText
          id="description"
          v-model.trim="record.name"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !record.name }"
        />
        <small class="p-error" v-if="!!errors?.name?.[0]">{{
          errors?.name?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-rules.name')" />

      <!-- Timezone -->
      <div class="field mb-1">
        <label for="timezone_id">{{ $t("xpbx.placeholders.timezone") }}</label>
        <div class="w-1/2">
          <form-select
            v-model="record.timezone_id"
            :options="timezonesData"
            name="timezone_id"
            id="timezone_id"
            :activeClass="true"
          />
        </div>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-rules.timezone')" />

      <!-- Period -->
      <div class="field mb-1">
        <label for="period">{{ $t("xpbx.placeholders.period") }}</label>
        <div class="w-20">
          <form-select
            v-model="record.period"
            :options="agentPeriodOptions"
            name="period"
            id="period"
            :activeClass="true"
          />
        </div>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-rules.period')" />

      <!-- Weigth -->
      <div class="field mb-1">
        <label for="weight">{{ $t("xpbx.placeholders.weight") }}</label>
        <div class="w-20">
          <form-select
            v-model="record.increase_weight"
            :options="weightOptions"
            name="weight"
            id="weight"
            :activeClass="true"
          />
        </div>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-rules.increase_weight')" />

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          text
          @click="createDialog = false"
        />
        <Button label="Save" icon="pi pi-check" text @click="create" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useTimezones from "@/modules/xpbx/composables/useTimezones";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import { validateCreatedQueueRule } from "@/composables/auth/queueValidatuions";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "QueueRules",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText,
    InputSwitch,
    Loader,
    QueueHint,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup() {
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);

    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();
    const { weightOptions, agentPeriodOptions } = useQueueOptions();
    const { timezones, findTimezones } = useTimezones();

    const timezonesData = computed(() => {
      if (!timezones.value) return [];

      return timezones.value.map((user) => {
        return {
          name: user.description,
          value: user.id,
        };
      });
    });

    // Data table functions
    const openNew = () => {
      record.value = {
        name: "",
        timezone_id: timezones.value[0].id,
        period: 0,
        increase_weight: 0,
      };
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      record.value = {
        name: "",
        timezone_id: timezones.value[0].id,
        period: 0,
        increase_weight: 0,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;
      await deleteQueueOption(
        selectedRecord.value,
        id,
        selectedRecords.value.id,
        "rules"
      );
      deleteDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        record.value = event.data;
        isEdit.value = true;
        createDialog.value = true;
      }
    };

    const updateRecord = async (data) => {
      const id = route.params.id;

      const formData = {
        ...data,
      };

      if (!formData.timezone_id) {
        formData.timezone_id = timezones.value[0].id;
      }

      if (formData.id) {
        await updateQueueOption(formData, id, formData?.id, "rules");
      }
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreatedQueueRule(data);
      if (isValid) {
        const id = route.params.id;
        const formData = {
          ...data,
          is_active: data.is_active ? 1 : 0,
        };

        await createQueueOption(formData, id, "rules");
        createDialog.value = false;
      }
    };

    const create = async () => {
      // const isValid = validateDomain(record.value);
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": isEdit.value && record?.value?.id === data?.id,
        },
      ];
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findTimezones();
      checkTableClick();
    });

    return {
      record,
      isEdit,
      errors,
      submitted,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      rowClass,
      timezones,
      timezonesData,
      weightOptions,
      agentPeriodOptions,
      // Data table functions
      reset,
      create,
      openNew,
      closeDialog,
      onRowSelect,
      findTimezones,
      onRowUnselect,
      createRecord,
      updateRecord,
      createQueueOption,
      updateQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>
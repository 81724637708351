<template>
  <div>
    <div class="card relative table-wrapper">
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        v-if="data && data?.length"
        removableSort
        paginatorPosition="top"
        paginator
        :rows="10"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        :rowClass="rowClass"
        selectionMode="single"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
        v-model:selection="selectedRecords"
      >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                :label="$t('xpbx.button.new')"
                icon="pi pi-plus"
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
              />
              <Button
                :label="$t('xpbx.button.delete')"
                icon="pi pi-trash"
                severity="danger"
                class="delete-button"
                @click="confirmDeleteSelected"
                :disabled="!selectedRecord?.id"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column sortable style="width: 25%" field="name" header="Name"></Column>
        <Column
          sortable
          style="width: 25%"
          field="timerange"
          header="Timerange"
        >
          <template #body="{ data }">
            {{ data?.timerange?.[0] }} -
            {{ data?.timerange?.[1] }}
          </template>
        </Column>
        <Column sortable style="width: 25%" field="value" header="Value">
          <template #body="{ data }">
            {{ data.audio_description }}
            <div class="flex gap-3">
              <AudioPlayer
                :id="data.announcement_id"
                v-if="data?.announcement_id"
              />
              {{ getFilename(data?.announcement_id) }}
            </div>
          </template>
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="action"
          header="Action"
        ></Column>
      </DataTable>

      <Loader v-else />
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.id">
          {{
            $t("notify.xpbx.message.confirm_delete", {
              delete: `action ${selectedRecord?.name}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          text
          @click="deleteSelectedRecords"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.pages.heading.edit_timeout_actions')
          : $t('xpbx.pages.heading.create_timeout_actions')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <div class="field mb-1">
        <label for="description mb-2">{{ $t("xpbx.placeholders.name") }}</label>
        <InputText
          id="description"
          v-model.trim="record.name"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !record.name }"
        />
        <small class="p-error" v-if="!!errors?.name?.[0]">{{
          errors?.name?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-action.name')" />

      <!-- Action -->
      <div class="field mb-1">
        <label for="action">{{ $t("xpbx.placeholders.action") }}</label>
        <div class="w-48">
          <form-select
            v-model="record.action"
            :options="fileTypeOptions"
            name="action"
            id="action"
            :activeClass="true"
          />
        </div>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-action.action')" />

      <!-- Value -->
      <div class="field mb-1">
        <label for="value">{{ $t("xpbx.placeholders.value") }}</label>
        <div class="w-1/2">
          <form-select
            v-model="record.value"
            :options="soundFileOptions"
            name="value"
            id="value"
            :activeClass="true"
          />
        </div>
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-action.value')" />

      <!-- Timerange -->
      <div class="field mb-1 flex gap-4">
        <div class="w-1/2">
          <label for="timerange">{{ $t("xpbx.placeholders.timerange") }}</label>
          <InputNumber
            v-model="record.timerange"
            inputId="minmax-buttons"
            showButtons
            :min="0"
            :max="100"
          />
          <small class="p-error" v-if="!!errors?.timerange?.[0]">{{
            errors?.timerange?.[0]
          }}</small>
          <QueueHint :title="$t('xpbx.hints.queue-action.timerange_1')" />
        </div>
        <div class="w-1/2">
          <div class="field mb-1">
            <label for="secondTimerange">{{
              $t("xpbx.placeholders.timerange")
            }}</label>
            <InputNumber
              v-model="record.secondTimerange"
              inputId="minmax-buttons"
              showButtons
              :min="0"
              :max="100"
            />
            <small class="p-error" v-if="!!errors?.secondTimerange?.[0]">{{
              errors?.secondTimerange?.[0]
            }}</small>
          </div>
          <QueueHint :title="$t('xpbx.hints.queue-action.timerange_2')" />
        </div>
      </div>

      <!-- Is active -->
      <div class="field mb-1 mt-3 flex items-center">
        <label for="is_active" class="mr-3">{{
          $t("xpbx.placeholders.active")
        }}</label>
        <InputSwitch v-model="record.is_active" />
      </div>
      <QueueHint :title="$t('xpbx.hints.queue-general.weight')" />

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          text
          @click="createDialog = false"
        />
        <Button label="Save" icon="pi pi-check" text @click="create" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useSoundFiles from "@/modules/xpbx/composables/useSoundFiles";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import AudioPlayer from "@/modules/xpbx/pages/settings/sound-files/audio.vue";
import { validateCreatedQueueAction } from "@/composables/auth/queueValidatuions";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "TimeoutActions",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText,
    InputSwitch,
    InputNumber,
    Loader,
    QueueHint,
    AudioPlayer,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();
    const record = ref({});
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);

    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();
    const { fileTypeOptions, actionOptions } = useQueueOptions();
    const { findSoundFIles, soundFiles } = useSoundFiles();

    const soundFileOptions = computed(() => {
      return soundFiles.value.map((file) => ({
        name: file.filename,
        value: file.id,
      }));
    });

    // Data table functions
    const openNew = () => {
      submitted.value = false;
      record.value = {
        name: "",
        action: "ivr",
        timerange: 0,
        secondTimerange: 0,
        value: soundFiles.value[0]?.id || "",
        is_active: false,
      };
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      record.value = {
        name: "",
        action: "ivr",
        timerange: 0,
        secondTimerange: 0,
        value: soundFiles.value[0]?.id || "",
        is_active: false,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecords.value.id) {
        await deleteQueueOption(
          selectedRecord.value,
          id,
          selectedRecords.value.id,
          "actions"
        );
      }
      deleteDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (!selectedRecord.value.id) return;

      if (selectedRecord.value?.id === event.data.id) {
        const form = {
          ...event.data,
          value: event.data.announcement_id,
          timerange: event.data.timerange?.[0] || "",
          secondTimerange: event.data.timerange?.[1] || "",
          is_active: event.data.is_active === 1 ? true : false,
        };
        record.value = form;
        isEdit.value = true;
        createDialog.value = true;
      }
    };

    const updateRecord = async (data) => {
      const id = route.params.id;

      const timerange = [+data.timerange, +data.secondTimerange];
      const formData = {
        id: data.id,
        name: data.name,
        announcement_id: data.value,
        is_active: data.is_active ? 1 : 0,
        action: data?.action || "ivr",
        timerange,
      };

      if (formData.id) {
        await updateQueueOption(formData, id, formData.id, "actions");
      }
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreatedQueueAction(data);
      if (isValid) {
        const id = route.params.id;
        const timerange = [+data.timerange || 0, +data.secondTimerange || 0];
        const formData = {
          name: data.name,
          announcement_id: data.value,
          is_active: data.is_active ? 1 : 0,
          action: data?.action || "ivr",
          timerange,
        };

        await createQueueOption(formData, id, "actions");
        createDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": isEdit.value && record?.value?.id === data?.id,
        },
      ];
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    const getFilename = (id) => {
      const file = soundFiles.value.find((file) => file.id === id);
      return file?.filename || "";
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      checkTableClick();
      await findSoundFIles();
    });

    return {
      record,
      isEdit,
      errors,
      submitted,
      soundFiles,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      actionOptions,
      fileTypeOptions,
      soundFileOptions,
      rowClass,
      // Data table functions
      reset,
      create,
      openNew,
      getFilename,
      closeDialog,
      onRowSelect,
      findSoundFIles,
      onRowUnselect,
      createRecord,
      updateRecord,
      createQueueOption,
      updateQueueOption,
      deleteQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>